import axios from "@/util/axios";

export default {
  namespaced: true,
  state () {
    return {
      fbAccounts: [],
      loadingFbAccounts: false,
      googleAccounts: [],
      twitterAccounts: [],
      loadingGoogleAccounts: false,
      loadingTwitterAccounts: false,
      amazonAccounts: [],
      amazonMarketplaces: []
    }
  },
  mutations: {
    setFbAccounts(state, payload) {
      state.fbAccounts = payload;
    },
    setTwitterAccounts(state, payload) {
      state.twitterAccounts = payload;
    },
    setAmazonAccounts(state, payload) {
      state.amazonAccounts = payload;
    },
    setGoogleAccounts(state, payload) {
      state.googleAccounts = payload;
    },
    setLoadingFbAccounts(state, payload) {
      state.loadingFbAccounts = payload;
    },
    setLoadingGoogleAccounts(state, payload) {
      state.loadingGoogleAccounts = payload;
    },
    setAmazonMarketplaces(state, payload) {
      state.amazonMarketplaces = payload;
    },
  },
  actions: {
    loadAmazonMarketplaces({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/amazon/marketplaces').then((response) => {
          commit('setAmazonMarketplaces', response.data);

          resolve(response);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
    loadFbAccounts({commit}) {
      return new Promise((resolve, reject) => {
        commit('setLoadingFbAccounts', true);
        axios.get('/ad-accounts/facebook').then((response) => {
          commit('setFbAccounts', response.data.data);

          resolve(response);
        }).catch((error) => {
          reject(error.response.data)
        }).finally(() => {
          commit('setLoadingFbAccounts', false);
        });
      });
    },
    loadTwitterAccounts({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/twitter/accounts').then((response) => {
          console.log(response.data.data);
          commit('setTwitterAccounts', response.data.data);

          resolve(response);
        }).catch((error) => {
          reject(error.response.data)
        })
      });
    },
    loadGoogleAccounts({commit}) {
      return new Promise((resolve, reject) => {
        commit('setLoadingGoogleAccounts', true);
        axios.get('/ad-accounts/google').then((response) => {
          commit('setGoogleAccounts', response.data.data);

          resolve(response);
        }).catch((error) => {
          reject(error.response.data)
        }).finally(() => {
          commit('setLoadingGoogleAccounts', false);
        });
      });
    },
    loadAmazonAccounts({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/ad-accounts/amazon').then((response) => {
          commit('setAmazonAccounts', response.data);

          resolve(response);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
  },
  getters: {
    getFbAccounts: (state) => state.fbAccounts,
    getAmazonAccounts: (state) => state.amazonAccounts,
    getGoogleAccounts: (state) => state.googleAccounts,
    getTwitterAccounts: (state) => state.twitterAccounts,
    getLoadingFbAccounts: (state) => state.loadingFbAccounts,
    getAmazonMarketplaces: (state) => state.amazonMarketplaces,
    // getLoadingGoogleAccounts: (state) => state.loadingGoogleAccounts,
    getLoadingGoogleAccounts (state, getters, rootState, rootGetters) {
      return !rootGetters['settings/getGoogleFetchDone'] || state.loadingGoogleAccounts;
    },
  }
}